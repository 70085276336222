// ---- Continous scrolling -----
var UI_PATTERNS = UI_PATTERNS || {};

UI_PATTERNS.continious_loader = {
  form_element: 'form.instant-search',
  load_notice_element: function() { return $(this.form_element).data('load-notice-element'); },
  results_element: function() { return $(this.form_element).data('update-container'); },
  repeating_element: '.entry',
  loading: false,
  page: 1,
  disabled: false,
  offset_value: null,
  scroll_bottom_value: null,
  setup: function() {
    // This continious loader needs at least a place to display results and a load-notice element to work
    if ($(this.results_element()).length == 0 || $(this.load_notice_element()).length == 0) {
      return false;
    }
    // Listen to scroll, and load more items if needed
    $(window).scroll(function() {
      if (this.should_load_more()) {
        this.load_more();
      }
    }.bind(this));
    this.reset();
  },
  reset: function() {
    this.page = 1;
    this.enable();
  },
  offset: function() {
    if (this.offset_value == null) { this.update(); }
    return this.offset_value;
  },
  scroll_bottom: function() {
    return $(window).scrollTop() + $(window).height();
  },
  update: function() {
    this.offset_value = $(this.load_notice_element()).offset().top;
    return this.offset_value;
  },
  should_load_more: function() {
    return this.loading == false && 
           this.disabled == false &&
           this.scroll_bottom() > this.offset();
  },
  disable: function() {
    this.disabled = true;
    $(this.load_notice_element()).hide();
  },
  enable: function() {
    this.disabled = false;
    this.loading = false;
    $(this.load_notice_element()).show();
    this.update();
  },
  count: function() {
    return $(this.load_notice_element()).data('total-size');
  },
  paginated_url: function() {
    this.page++;
    
    // Construct URL with action + serilized form (and remove empty params)
    var url = $(this.form_element).attr('action') + '?' + 
              $(this.form_element).serialize().replace(/[^&]+=\.?(?:&|$)/g, '');

    // Replace or add page number
    url = /page=(\d+)/i.test(url) ? 
          url.replace(/page=(\d+)/i, 'page=' + this.page) : 
          (url + '&page=' + this.page);
    return url;
  },
  load_more: function() {
    var url = this.paginated_url();
    $.ajax({
      url: url,
      context: $(this.results_element()),
      beforeSend: function() {
        this.loading = true;
      }.bind(this),
      success: function(responseText) {
        var html = $("<div>").append($($.parseHTML(responseText)).find(this.repeating_element)).html();
        $(html).insertAfter($(this.repeating_element).last());

        // Update action point (scroll offset) for when we should load more entries...
        this.update();
        
        // Open up for loading more entries upon scroll
        this.loading = false;

        // If we've fetched all items to fetch, stop...
        if ($(this.repeating_element).length >= this.count()) {
          this.disable();
        }
      }.bind(this)
    });
  }
}

// Browse for entries
$(document).on('turbolinks:load', function () {
  UI_PATTERNS.continious_loader.setup();
});

// Browse for entries
$(document).on('turbolinks:load', function () {

  // Make search form stick, when scrolling
  if ($('.filter-form').length > 0) {
    var bg_trigger_position = $('.container[role="document"]').offset().top - $('[role="navigation"]').height();
    $(window).on('load scroll', function() {
      var current_position = $(this).scrollTop(),
          fixed_trigger = !($('.filter-form').is(":visible")),
          bg_trigger = (current_position >= bg_trigger_position);

      $('.filter-form').toggleClass('fixed', bg_trigger);

    });
  }

  $('form.instant-search').unbind('submit');
  $('form.instant-search').submit(function(event) {
    event.preventDefault();
  });
  $('form.instant-search input, form.instant-search select').unbind('change');
  $('form.instant-search input, form.instant-search select').change(function(event) {
    event.preventDefault();
    var form = $(event.currentTarget).closest('form');
    var serialized_form = form.serialize().replace(/[^&]+=\.?(?:&|$)/g, '');
    var url = form.attr('action') + '?' + serialized_form;
    if (form.data('update-container') && $(form.data('update-container')).length > 0) {
      $.ajax({
        url: url,
        context: $(form.data('update-container')),
        beforeSend: function() {
          $(this).addClass("loading");
        },
        success: function(responseText) {

          $(form.data('update-container')).html(
            // Locate the right elements in a dummy div
            // Exclude scripts to avoid IE 'Permission Denied' errors
            $("<div>").append($.parseHTML(responseText)).find(form.data('update-container')).html()
          );

          $(form.data('load-notice-element')).replaceWith(
            $("<div>").append($($.parseHTML(responseText)).find(form.data('load-notice-element'))).html()
          );

          $(form.data('results-count-element')).replaceWith(
            $("<div>").append($($.parseHTML(responseText)).find(form.data('results-count-element'))).html()
          );
          $(form.data('results-count-element')).addClass('effect-tada');

          // Update browser location bar
          window.history.pushState({ turbolinks: true, url: url }, '', url)

          // Remove overview elements, if we're not doing a blank search
          if (serialized_form != '') {
            $('.overview-element').hide();
          } else {
            $('.overview-element').show();
          }

          $(this).removeClass("loading");

          $(document).trigger('page:reloadbasics')

          if (UI_PATTERNS.continious_loader) {
            UI_PATTERNS.continious_loader.reset();
          }
        }
      });
    } else {
      location.href = url;
    }
  });

});